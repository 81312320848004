'use client'
import { ContentTextImageSurroundStoryblok } from '@/app/types/component-types-sb'
import styles from './ContentTextImageSurround.module.css'

import React from 'react'
import Image from '../../globals/image'
import { storyblokEditable } from '@storyblok/react/rsc'
import clsx from 'clsx'
import { motion } from 'framer-motion';
import Button from '../../globals/button'
import CustomLink from '../../globals/link'
import RichTextRenderer from '../../globals/richtext'

const ContentTextImageSurround = ({ blok }: { blok: ContentTextImageSurroundStoryblok }) => {

  const scrollToGroups = () => {
    const groups = document.getElementsByClassName('story-blokgroups')[0]
    if (!groups) return
    const offset = 100
    const elementTop = groups.getBoundingClientRect().top + window.scrollY
    const targetPosition = elementTop - offset
    window.scrollTo({ top: targetPosition, behavior: 'smooth' })
  }

  const animationProps = {
    initial: { opacity: 0, y: 25 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true, margin: "-100px" },
    transition: { duration: 1, ease: "easeInOut" }
  };

  return (
    <div className='page-section' {...storyblokEditable(blok)}>
      <div className='container'>
        <div className={clsx(styles.topSection, blok.flip ? styles.topSectionFlipped : null)}>
          <motion.div className={styles.topImage} {...animationProps}>
            <Image asset={blok.image_side}
              size={{ width: 500, height: 700 }}
            />
          </motion.div>
          <motion.div className={styles.textSection}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            {(blok.title && (blok.title.trim() !== '')) && (
              <h2 className={styles.title}>{blok.title}</h2>
            )}
            <div className={`${styles.text} text-content`}>
              <RichTextRenderer content={blok.text_content} />
            </div>
            {(blok.button && (blok.button.length > 0)) && (
              <>
                {(blok.button[0].component === 'button') ? (
                  <Button onClick={scrollToGroups} variant='outline'>
                    {blok.button[0].label}
                  </Button>
                ) : (
                  <>
                    {blok.button[0].component === 'nested_link' ? (
                      <CustomLink
                        link={blok.button[0]}
                        variant='outline'
                        asButton
                      />
                    ) : (
                      <CustomLink
                        link={blok.button[0]}
                        variant='outline'
                        asButton
                      />
                    )}
                  </>
                )}
              </>
            )}
          </motion.div>
        </div>

        <div className={clsx(styles.bottomSection, blok.flip ? styles.bottomSectionFlipped : null)}>
          <motion.div className={styles.bottomSideImage} {...animationProps}>
            <Image asset={blok.image_bottom_side}
              size={{ width: 800, height: 300 }}
            />
          </motion.div>
          <motion.div className={styles.bottomImage} {...animationProps}>
            <Image asset={blok.image_bottom}
              size={{ width: 480, height: 270 }}
            />
          </motion.div>
        </div>

      </div>
    </div>
  )
}

export default ContentTextImageSurround