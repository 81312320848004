import { Promotion } from "@/app/types/lambda-types"
import PromotionItem from "./PromotionItem";

const Promotions = ({ promotions }: { promotions: Promotion[] | null | undefined }) => {
  return (
    <>
      {promotions ? promotions.map((promotion, key) => (
        <PromotionItem promotion={promotion} key={key} />
      )) : null}
    </>
  )
}

export default Promotions;