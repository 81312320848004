"use client"

import { motion, MotionProps } from 'framer-motion';
import { ComponentPropsWithRef, ElementType, forwardRef } from 'react';

type MotionWrapperProps<T extends ElementType> = {
  type?: keyof typeof motion;
  children?: React.ReactNode;
} & Omit<ComponentPropsWithRef<T>, 'ref'> & MotionProps;

const MotionWrapper = forwardRef(
  <T extends ElementType = typeof motion.div>(
    { type, children, ...props }: MotionWrapperProps<T>,
    ref: React.ForwardedRef<HTMLElement>
  ) => {
    const Component = (type ? motion[type] : motion.div) as ElementType;
    return (
      <Component ref={ref} {...props}>
        {children}
      </Component>
    );
  }
);

MotionWrapper.displayName = 'MotionWrapper';

export default MotionWrapper;