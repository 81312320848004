import React from 'react'
import { storyblokEditable } from "@storyblok/react/rsc";
import type { HeaderStoryblok } from '@/app/types/component-types-sb';
import FullHeader from './FullHeader';
import ShortHeader from './ShortHeader';
import MediumHeader from './MediumHeader';
import { serverStore } from '@/utils/lib/serverstore';

const Header = ({ blok }: { blok: HeaderStoryblok }) => {
  serverStore.set('headerImage', blok.background_image)
  return (
    <div {...storyblokEditable(blok)}>
      {blok.style === '2' ? (
        <ShortHeader blok={blok} />
      ) : null}
      {blok.style === '1' ? (
        <FullHeader blok={blok} />
      ) : null}
      {blok.style === '3' ? (
        <MediumHeader blok={blok} />
      ) : null}
    </div>
  );
}

export default Header