import styles from '../Form.module.css';

interface TextInputProps {
  inputName: string;
  label: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  required?: boolean;
}

const TextField = ({
  inputName,
  label,
  value,
  placeholder = '',
  disabled,
  onChange,
  type = 'text',
  required = false
}: TextInputProps) => {
  return (
    <div className={styles.formGroup}>
      <label className={styles.label}>
        <span className={styles.labelText}>{label}</span>
        <input
          className={styles.input}
          type={type}
          name={inputName}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
        />
      </label>
    </div>
  );
}

export default TextField;