import day from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import StoryblokClient from 'storyblok-js-client'

day.extend(customParseFormat);

/**
 * A wrapper around the dayjs function to format dates.
 *
 * @param {string | number | Date | dayjs.Dayjs} date - The date to format.
 * @param {string} [format="DD MMM YYYY"] - The format of the returned string.
 * @returns {string} The formatted date string.
 */
export const formatDate = day;

/**
 * Whether the application is running in production mode or not.
 *
 * @type {boolean}
 */
export const isProduction = process.env.NEXT_PUBLIC_PRODUCTION_MODE ?
    process.env.NEXT_PUBLIC_PRODUCTION_MODE
    : (process.env.NODE_ENV === "production");

/**
 * Returns the base URL for the API, based on whether the server is running in production mode
 * or not. If in production mode, returns the production URL, otherwise returns the development
 * URL.
 *
 * @returns {string} The base URL for the API.
 */
export const getAPIHost = () => {
    return ((isProduction === true) || (isProduction === 'true')) ? 'https://api.cruisenation.com' : 'http://localhost:8088';
}


export const getFrontendHost = () => {
    // Browser should use relative URL
    if (typeof window !== 'undefined') {
        return '';
    }

    if (process.env.VERCEL_URL) {
        // If on a build/runtime server side
        return `https://${process.env.VERCEL_URL}`;
    }

    if (process.env.NEXT_PUBLIC_BASE_URL) {
        // If on a local host server side
        return process.env.NEXT_PUBLIC_BASE_URL;
    }
}


export const Storyblok = new StoryblokClient({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY || process.env.STORYBLOK_ACCESS_TOKEN,
    cache: {
        clear: 'auto',
        type: 'memory',
    }
})