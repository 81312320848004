import React from "react";
import { SbBlokData, StoryblokServerComponent, storyblokEditable } from "@storyblok/react/rsc";
import type { ColumnStoryblok } from "@/app/types/component-types-sb";
import Container from "../../globals/container";

const Column = ({ blok }: { blok: ColumnStoryblok }) => {
  return (
    <div className="page-section" {...storyblokEditable(blok)}>
      <Container>
        {
          blok.content?.map((nestedBlok: SbBlokData) => (
            <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))
        }
      </Container>
    </div>
  )
}

export default Column;