'use client'
import { CruiseLinePromoCarouselSlideStoryblok, CruiseLinePromoCarouselStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import useEmblaCarousel from "embla-carousel-react";
import React from "react";
import Image from '@/app/components/globals/image';
import CustomLink from "@/app/components/globals/link";
import styles from './CruiseLinePromoCarousel.module.css';
import Autoplay from 'embla-carousel-autoplay'

const CruiseLinePromoCarousel = ({ blok }: { blok: CruiseLinePromoCarouselStoryblok }) => {

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      align: 'center',
      dragFree: true
    },
    [Autoplay({
      delay: 4000,
      stopOnInteraction: true,
      stopOnMouseEnter: true,
      playOnInit: true
    })]
  )

  return (
    <div {...storyblokEditable(blok)} className="page-section">
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {
            blok.slides?.map((nestedBlok: CruiseLinePromoCarouselSlideStoryblok, index) => (
              <div key={index} className={styles.slide}>
                <div className={styles.innerWrapper}>
                  <CustomLink link={nestedBlok.link} title={nestedBlok.image?.alt || ''}>
                    <Image asset={nestedBlok.image || ''} />
                  </CustomLink>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default CruiseLinePromoCarousel;