'use client'
import { GroupStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from '@storyblok/react'
import React, { useState, useEffect } from 'react'
import styles from './Group.module.css'
import Container from '../../globals/container'
import { Filter, GroupResults, Holiday } from '@/app/types/lambda-types'
import { getDynamicGroups, getFeaturedDeals, getPhoneNumber } from '@/utils/queries'
import DynamicGroup from '../../globals/dynamic_group'
import { getCookie } from '@/utils/cookies'
import CustomLink from '../../globals/link'
import Terms from './Terms'

interface GroupProps {
  blok: GroupStoryblok
  initGroup: GroupResults | undefined | Holiday[]
  initialFilters: Filter
}

const GroupBlok = ({ blok, initGroup, initialFilters }: GroupProps) => {

  const [group, setGroup] = useState<GroupResults | Holiday[] | undefined>(initGroup);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

  const runWithinUseEffect = () => {
    async function fetchDynamicGroups() {
      if (!blok.group_uuid) return;

      if (blok.data_field === "2") {
        const result = await getFeaturedDeals(
          blok.group_uuid
        );
        if (result) {
          setGroup(result);
        }
      } else {
        const result = await getDynamicGroups(
          blok.group_uuid,
          blok.page_size || '12',
          filters.sort_by ? filters.sort_by : blok.sort_by || undefined,
          blok.sort_direction || 'ASC',
          true,
          page,
          filters.ship === "*" ? undefined : filters.ship,
          filters.region === "*" ? undefined : filters.region,
          filters.date === "*" ? undefined : filters.date
        );
        if (result) {
          setGroup(result);
        }
      }
      setIsLoading(false);
    }
    fetchDynamicGroups();
  }

  useEffect(() => {
    const fetchData = async () => {
      const phoneNumberResponse = await getPhoneNumber(getCookie('aff'));
      setPhoneNumber(phoneNumberResponse);
    }
    fetchData()
  }, [])

  useEffect(() => {
    runWithinUseEffect();
  }, [page, filters, blok])

  return (
    <div className="page-section story-blokgroups" {...storyblokEditable(blok)}>
      <Container>
        {(blok.show_title !== false) && (
          <h2 className="section-header">
            {blok.title && blok.title !== '' ? blok.title : 'Amazing Holiday Deals' /*'Hand-Picked Holidays'*/}
          </h2>
        )}
        {group ? (
          <DynamicGroup
            group={group}
            data_field={blok.data_field}
            uuid={blok.group_uuid}
            initSort={blok.sort_by}
            show_pagination={blok.show_pagination}
            initialFilters={initialFilters}
            onPageChange={(index) => {
              setPage(index);
            }}
            onFiltersChange={(filters) => {
              setFilters(filters);
            }}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />) : (
          <DynamicGroup
            group={group}
            data_field={blok.data_field}
            uuid={blok.group_uuid}
            initSort={blok.sort_by}
            show_pagination={blok.show_pagination}
            initialFilters={initialFilters}
            onPageChange={(index) => {
              setPage(index);
            }}
            onFiltersChange={(filters) => {
              setFilters(filters);
            }}
            setIsLoading={setIsLoading}
            isLoading={true}
          />
        )}
        <Terms phoneNumber={phoneNumber} />
      </Container>
    </div>
  )
}

export default GroupBlok