import React from "react";
import TypeIcon from "../../globals/holiday/TypeIcon";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import type { StarBuyStoryblok } from "@/app/types/component-types-sb";
import { getStarBuyHoliday } from "@/utils/queries";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import { faShip } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import styles from './StarBuy.module.css';
import Image from '@/app/components/globals/image';
import FromPrice from "../../globals/from-price";
import Included from "./Included";
import Promotions from "../../globals/promotions";
import CustomLink from "../../globals/link";
import getIncluded from "@/utils/data-manipulation/getIncluded";
import StarBuyClient from "./StarBuyClient";

const StarBuy = async ({ blok }: { blok: StarBuyStoryblok }) => {
	
	dayjs.extend(advancedFormat);
	let isLoading = true;

	async function fetchHoliday() {
		if (!blok.group) return;

		const result = await getStarBuyHoliday(blok.group);
		if (result.status === true) {
			return result.holiday
		}
	}

	const holiday = await fetchHoliday();
	if(holiday) isLoading = false

	const header = (
		holiday ?
			<header className={styles.containerHeader}>
				<TypeIcon type={holiday.type} />

				<div className={styles.headerDetails}>
					<h3>{holiday.name}</h3>
					<div className={styles.itinerary}>
						{holiday.itinerary ? holiday.itinerary!.collection.map((port, key) => (
							<span key={key}>
								{port.name}
								{key < holiday.itinerary!.collection.length - 1 ? ' | ' : ''}
							</span>
						)) : null}
					</div>
					<div className={styles.details}>
						<span className={styles.detail}>
							<FontAwesomeIcon icon={faCalendarDay as IconProp} />
							{dayjs(holiday.start_date).format('Do MMM YY')}
						</span>
						<span className={styles.detail}>
							<FontAwesomeIcon icon={faShip as IconProp} />
							{holiday.sail_nights}-night full-board cruise aboard
							<span className={styles.ship}>{holiday.ship?.name}</span>
						</span>
					</div>
				</div>
				{holiday.from_price ?
					<FromPrice price={parseFloat(holiday.from_price).toFixed(0)} />
					: null
				}
				<div className={styles.lineLogo}>
					<Image asset={holiday!.line!.logo} />
				</div>
			</header> : null
	);

	const body = (
		holiday ?
			<>
				<footer className={styles.containerFooter}>
					<section className={styles.promotions}>
						<Promotions promotions={holiday.promotions} />
					</section>
					<footer className={styles.footer}>
						<div className={styles.includedSection}>
							<span className={styles.includedLabel}>
								INCLUDED:
							</span>
							<div className={styles.includeds}>
								{holiday.extras ? getIncluded(holiday.extras).map((extra, key) => (
									<Included key={key} holidayExtra={extra} />
								)) : null}
							</div>
						</div>
						<div className={styles.buttonContainer}>
							<CustomLink href={holiday.url} className={styles.viewButton} asButton>
								View Deal
							</CustomLink>
						</div>
					</footer>
				</footer>
			</>
			: null
	)

	return (
		<StarBuyClient header={header} body={body} blok={blok} isLoading={isLoading} holiday={holiday} />
	);
}

export default StarBuy;