import React from "react";
import type { VideoBackgroundStoryblok, VimeoVideoStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import styles from './VideoBackground.module.css';
import Container from "../../globals/container";
import VideoVimeo from "../vimeo_video";

const VideoBackground = ({ blok }: { blok: VideoBackgroundStoryblok }) => {
  return (
    <div className="page-section" {...storyblokEditable(blok)}>
      <div className={styles.wrapper}>
        {/* <Image asset={blok.image} /> */}
        <Container>
          {
            blok.video.map((video: VimeoVideoStoryblok, index) => (
              <VideoVimeo key={index} blok={video} />
            ))
          }
        </Container>
      </div>
    </div>
  );
}

export default VideoBackground;