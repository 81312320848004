import React from "react";
import { ArticleStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from "./Articles.module.css";
import Link from "next/link";

const Article = ({ blok }: { blok: ArticleStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <Link href={`/knowledge-base/article/${blok.item}`} className={styles.article}>
        <h5>{blok.title}</h5>
      </Link>
    </div>
  );
};

export default Article;
