"use client"
import React, { useState, useEffect } from "react";
import type { VimeoVideoStoryblok } from "@/app/types/component-types-sb";
import styles from './VimeoVideo.module.css';
import { storyblokEditable } from "@storyblok/react";

const VideoVimeo = ({ blok }: { blok: VimeoVideoStoryblok }) => {
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    // Create an intersection observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && blok.url) {
            setShouldLoad(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 } // Load when 10% of the element is visible
    );

    // Get the placeholder element
    const placeholder = document.getElementById(`vimeo-placeholder-${blok.title}`);
    if (placeholder) {
      observer.observe(placeholder);
    }

    return () => observer.disconnect();
  }, [blok.title]);

  return (
    <div {...storyblokEditable(blok)} className={styles.wrapper} id={`vimeo-placeholder-${blok.title}`}>
      {shouldLoad ? (
        <iframe
          src={blok.url}
          title={blok.title}
          loading="lazy"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen={true}
          className={styles.iframe}
        />
      ) : (
        // Placeholder while video is not loaded
        <div
          className={`${styles.iframe} bg-gray-200 flex items-center justify-center`}
          aria-label={`Video placeholder for ${blok.title}`}
        >
          <span className="text-gray-500">Loading video...</span>
        </div>
      )}
    </div>
  );
};

export default VideoVimeo;