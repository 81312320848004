import React from 'react'
import { storyblokEditable, StoryblokServerComponent, SbBlokData } from "@storyblok/react/rsc";
import type { GridStoryblok } from '@/app/types/component-types-sb';

const Grid = ({ blok }: { blok: GridStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      {
        blok.columns?.map((nestedBlok: SbBlokData) => (
          <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))
      }
    </div>
  );
}

export default Grid