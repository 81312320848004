import React from 'react'
import styles from './Header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@awesome.me/kit-d4c82d9167/icons/classic/solid'
import { Article, Category } from '@/app/types/lambda-types'
import Link from 'next/link'
import DynamicIcon from '@/app/components/globals/dynamic-icon'

type Props = {
  article?: Article
  category?: Category
}

const SearchFilterDisplay = ({ article, category }: Props) => {
  return (
    <Link href={article ? `/knowledge-base/article/${article.uuid}` : `/knowledge-base/category/${category?.uuid}`}>
    <div className={styles.searchFilterItem}>
      <DynamicIcon iconName={article?.icon || category?.icon || ''} style="solid" />
      {article ? (
        <>
          {article.name}
          <p dangerouslySetInnerHTML={{ __html: article.description }} />
        </>
      ) : <>
        {category?.name}
      </>}
    </div>
    </Link>
  )
}

export default SearchFilterDisplay