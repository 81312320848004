import { InformationTimes } from "@/app/components/globals/navigation/Navigation";
import { getCookie, setCookie } from "./cookies";
import { CruiseShip, Filter, GroupResults, Holiday, KnowledgeBaseArticleViewType, KnowledgeBaseChildrenRoutes, KnowledgeBaseHeaderSearchFilter, PortGuide, Region, RegionWeather, Ship, ShipDetail, StoryblokState, WhatsIncludedType } from "@/app/types/lambda-types";
import { getAPIHost, getFrontendHost } from "./configs";
import { FeefoMerchantResponse, FeefoReviewsResponse } from "@/app/types/feefo-types";

export const getStarBuyHoliday = async (uuid: string): Promise<{
	holiday?: Holiday;
	status: boolean;
}> => {
	try {
		const response = await fetch(getAPIHost() + '/api/dynamic-group/star-buy/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ uuid }),
			next: { revalidate: 300 }, // 5 minutes
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch starbuy data");
	}
};

export const getBlogPosts = async (per_page?: number, page?: number): Promise<WordPressPost[]> => {
	try {
		const response = await fetch(
			`https://www.cruisenation.com/blog/wp-json/wp/v2/posts?_embed=true&per_page=${per_page || 4}&page=${page || 1}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'Cache-Control': 'max-age=3600, s-maxage=3600, stale-while-revalidate=86400',
				},
				next: { revalidate: 300 },
			}
		);

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch blog posts");
	}
};

export const getDynamicGroups = async (
	uuid: string,
	pageSize: string | number,
	sortBy?: string,
	sortDirection?: string,
	withImages?: boolean,
	page?: number,
	ship?: any,
	region?: any,
	date?: any
): Promise<GroupResults> => {
	try {
		const response = await fetch(getAPIHost() + '/api/dynamic-group/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uuid,
				pageSize: pageSize || '12',
				sortBy: sortBy || undefined,
				sortDirection: sortDirection || undefined,
				withImages: withImages || false,
				page: page || undefined,
				filter: {
					ship_filter_by: ship || undefined,
					holiday_region_filter_by: region || undefined,
					month_filter_by: date || undefined
				}
			}),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch dynamic groups data");
	}
};

export const getFeaturedDeals = async (uuid: string): Promise<Holiday[]> => {
	try {
		const response = await fetch(getAPIHost() + '/api/featured_deals/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ uuid }),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch featured deals data");
	}
};

export const getCruiseShipsByLineId = async (id: string): Promise<CruiseShip[]> => {
	try {
		const response = await fetch(getAPIHost() + '/api/ships/by_line_id/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ line_id: id }),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch cruiseship by line id data");
	}
};

export const getWeatherChartData = async (region_id?: string, sub_region_id?: string, port_guide_id?: string): Promise<RegionWeather> => {
	try {
		const response = await fetch(getAPIHost() + (sub_region_id ? '/api/weather_by_region/sub_region/' : '/api/weather_by_region/weather/'), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				region_id,
				sub_region_id,
				port_guide_id
			}),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch weather data");
	}
};

export const getRegionMapCoordinates = async ({ region_id, sub_region_id }: { region_id?: string, sub_region_id?: string }): Promise<PortGuide[]> => {
	try {
		const response = await fetch(getAPIHost() + '/api/map-box-data/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				region_id,
				sub_region_id
			}),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch map data");
	}
};

export const knowledgeBaseSearchFilter = async (query: string): Promise<KnowledgeBaseHeaderSearchFilter> => {
	if (query.trim().length === 0) return { articles: [], categories: [], total_articles: 0, total_categories: 0 };
	try {
		const response = await fetch(`${getAPIHost()}/api/knowledge-base/search_route/?search=${query}`, {
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to search knowledge base");
	}
};

export const getKnowledgeBaseChildrenRoutes = async (uuid: string): Promise<KnowledgeBaseChildrenRoutes> => {
	try {
		const response = await fetch(`${getAPIHost()}/api/knowledge-base/children-route/?uuid=${uuid}`, {
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch knowledge base children routes");
	}
};

export const getKnowledgeBaseArticleView = async (uuid: string): Promise<KnowledgeBaseArticleViewType> => {
	try {
		const response = await fetch(`${getAPIHost()}/api/knowledge-base/article/?uuid=${uuid}`, {
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch knowledge base article view");
	}
};

export const getWhatsIncluded = async (props: {
	cruise_line_tt_id: number,
	has_flights?: 0 | 1,
	has_hotels?: 0 | 1,
}): Promise<WhatsIncludedType[]> => {
	try {
		const { cruise_line_tt_id, has_flights, has_hotels } = props;
		const response = await fetch(getAPIHost() + '/api/cruise_line_included/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				cruise_line_tt_id,
				has_flights: has_flights ? (has_flights === 1 ? 1 : 0) : undefined,
				has_hotels: has_hotels ? (has_hotels === 1 ? 1 : 0) : undefined,
			}),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch whats included");
	}
};

export const getShipDetailsByTTID = async (tt_id: number): Promise<ShipDetail> => {
	try {
		const response = await fetch(getAPIHost() + '/api/ships/by_tt_id/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ tt_id }),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch ship details by ttid");
	}
};

export const getOpeningTimes = async (): Promise<InformationTimes> => {
	try {
		const salesResponse = await fetch(`${getAPIHost()}/api/opening_hours/?type=sales`, {
			next: { revalidate: 300 },
		});

		const supportResponse = await fetch(`${getAPIHost()}/api/opening_hours/?type=support`, {
			next: { revalidate: 300 },
		});

		if (!salesResponse.ok || !supportResponse.ok) throw new Error('Network response was not ok');

		const sales = await salesResponse.json();
		const support = await supportResponse.json();

		return { sales, support };
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch opening times");
	}
};

export const checkStoryblokState = async (baseUrl?: string, accessToken?: string, pathname?: string): Promise<{
	data: StoryblokState,
	cacheTime?: number
}> => {
	try {
		const response = await fetch(getAPIHost() + '/api/storyblok/webhook/check-published/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				full_slug: pathname
			}),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		const data = await response.json();

		if (data.message === 'true') {
			const cacheResponse = await fetch(`https://api.storyblok.com/v2/cdn/spaces/me/?token=${accessToken ? accessToken : process.env.NEXT_PUBLIC_STORYBLOK_KEY}`, {
				headers: {
					'Content-Type': 'application/json'
				},
				next: { revalidate: 300 },
			});

			if (!cacheResponse.ok) throw new Error('Network response was not ok');
			const cacheData = await cacheResponse.json();

			return {
				data,
				cacheTime: cacheData.space.version
			};
		}

		return {
			data,
			cacheTime: undefined
		};
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch storyblok state");
	}
};

export const getShips = async (uuid?: string): Promise<Ship[]> => {
	try {
		const response = await fetch(getAPIHost() + '/api/storyblok/ships/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				cruise_line_id: uuid
			}),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch ships");
	}
};

export const fetchFilters = async (uuid?: string): Promise<Filter> => {
	try {
		const response = await fetch(getAPIHost() + '/api/dynamic-group/filters/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ uuid }),
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch filters");
	}
};

export const getRegions = async (): Promise<Region[]> => {
	try {
		const response = await fetch(getAPIHost() + '/api/storyblok/region/', {
			next: { revalidate: 300 },
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch regions");
	}
};

export const getPhoneNumber = async (aff: string | null | undefined): Promise<string> => {
	try {
		const response = await fetch(
			`https://api.cruisenation.com/api/aff-to-phone/${aff && aff !== '' ? '?aff=' + aff : ''}`,
			{
				next: { revalidate: 300 },
			}
		);

		if (!response.ok) throw new Error('Network response was not ok');
		const data = await response.json();
		return data.phone_number;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch phone number");
	}
};

export const getFeefoData = async (): Promise<FeefoMerchantResponse> => {
	try {
		const response = await fetch(
			'https://api.feefo.com/api/10/reviews/summary/all?merchant_identifier=cruise-nation', {
			next: { revalidate: 300 },
			headers: {
				'Content-Type': 'application/json'
			}
		});

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch feefo data");
	}
};

export const getFeefoReviewsData = async (page: number, pageSize: number): Promise<FeefoReviewsResponse> => {
	try {
		const urlParams = new URLSearchParams({
			merchant_identifier: 'cruise-nation',
			page: (page || '1').toString(),
			page_size: (pageSize || '12').toString()
		});

		const response = await fetch(
			`https://api.feefo.com/api/10/reviews/all?${urlParams}`,
			{
				next: { revalidate: 300 },
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);

		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch feefo reviews data");
	}
};