import React from 'react';
import { storyblokEditable } from '@storyblok/react/rsc';
import type { ContentStoryblok } from '@/app/types/component-types-sb';
import RichTextRenderer from '../../globals/richtext';

const Content = ({ blok }: { blok: ContentStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} className='text-content'>
      {
        blok.header ?
          (<h2 className='section-header'>{blok.header}</h2>) : null
      }
      <RichTextRenderer content={blok.content} />
    </div>
  );
}

export default Content;