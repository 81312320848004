import React from 'react'
import styles from "./Articles.module.css";
import { ArticleGroupStoryblok, ArticlesStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import Container from '@/app/components/globals/container';
import PageSection from '@/app/components/globals/page_section';
import Articles from './Articles';


const ArticleGroup = ({ blok }: { blok: ArticleGroupStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h4>{blok.title}</h4>
          <div className={styles.articleGroup}>
            {blok.articles.map((article: ArticlesStoryblok) => (
              <Articles blok={article} key={article._uid} />
            ))}
          </div>
        </Container>
      </PageSection>
    </div>
  )
}

export default ArticleGroup