'use client'
import { InspirationalIntroStoryblok } from "@/app/types/component-types-sb"
import styles from "./Intro.module.css"
import PageSection from "../../globals/page_section"
import { storyblokEditable } from "@storyblok/react/rsc"
import Container from "../../globals/container"
import { motion } from 'framer-motion'

const InspirationalIntro = ({ blok }: { blok: InspirationalIntroStoryblok }) => {

    const animationProps = {
        initial: { opacity: 0, y: 25 },
        whileInView: { opacity: 1, y: 0 },
        viewport: { once: true, margin: "-100px" },
        transition: { duration: 0.6, ease: "easeInOut" }
    };

    return (
        <div {...storyblokEditable(blok)}>
            <PageSection>
                <Container>
                    <motion.div {...animationProps}>
                        <p className={styles.intro}>{blok.text}</p>
                    </motion.div>
                </Container>
            </PageSection>
        </div>
    )
}

export default InspirationalIntro