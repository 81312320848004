import { KnowledgeBaseArticleViewType } from "@/app/types/lambda-types";
import { getKnowledgeBaseArticleView } from "@/utils/queries";
import React from "react";
import ArticleView from "./ArticleView";
import { serverStore } from "@/utils/lib/serverstore";

const ArticleWrapper = async () => {
  const uuid = serverStore.get('uuid');

  const fetchData = async () => {
    const data = await getKnowledgeBaseArticleView(uuid as string);
    return data
  };

  const result: KnowledgeBaseArticleViewType = await fetchData();

  return (
    <ArticleView result={result} />
  );
};

export default ArticleWrapper;