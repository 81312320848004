import { TabbedGroupCollectionStoryblok } from "@/app/types/component-types-sb";
import React from "react";
import GroupBlok from "../group/Group";
import { storyblokEditable } from "@storyblok/react";
import { getAPIHost } from "@/utils/configs";
import { Group, Holiday } from "@/app/types/lambda-types";
import { getDynamicGroups, getFeaturedDeals } from "@/utils/queries";
import TabbedGroupCollectionClient from "./TabbedGroupCollectionClient";
import Container from "../../globals/container";

async function getGroupData(uuid: string): Promise<Group[]> {
  try {
    const response = await fetch(`${getAPIHost()}/api/dynamic-group/collection/`, {
      method: 'POST',
      body: JSON.stringify({ uuid }),
      headers: {
        'Content-Type': 'application/json'
      },
      ...process.env.NODE_ENV === 'production' ?
        { next: { revalidate: 60 * 10 } } :  // 10 minute cache revalidate
        { cache: 'no-store' }
    });

    return response.json();
  } catch (error) {
    console.error('Error fetching group data:', error);
    return [];
  }
}

async function getGroupHolidays(group: Group): Promise<Group> {
  try {
    const featured_deals = await getFeaturedDeals(group.uuid);

    return { ...group, featured_deals };
  } catch (error) {
    console.error('Error fetching holidays for group:', group.uuid, error);
    return { ...group, featured_deals: [] };
  }
}

const TabbedGroupCollection = async ({ blok }: { blok: TabbedGroupCollectionStoryblok }) => {
  if (!blok.collection) return null;

  const groupData = await getGroupData(blok.collection);

  let groups: Group[] = [];

  if (groupData && groupData.length) {
    groups = await Promise.all(
      groupData.map(group => getGroupHolidays(group))
    );
  }

  // Filter groups where featured_deals length is less than 1 and not null
  const featuredGrouped = groups.filter(group => (group.featured_deals?.length ?? 0) > 0);

  return (
    <section className="page-section" {...storyblokEditable(blok)}>
      <Container>
        <h2 className="section-header">
          {blok.title && blok.title !== '' ? blok.title : 'Hand-picked Holidays' /*'Featured Holiday Deals'*/}
        </h2>
        <TabbedGroupCollectionClient groups={featuredGrouped} />
      </Container>
    </section>
  );
}

export default TabbedGroupCollection;