import React, { ReactElement } from 'react'
import components from '../../globalComponents';
import { BlockTypes, MarkTypes, StoryblokRichText, StoryblokRichTextNode } from '@storyblok/react'
import { RichtextStoryblok } from '@/app/types/component-types-sb';

type Props = {
  resolver?: { [key: string]: (node: StoryblokRichTextNode<ReactElement>) => ReactElement }
  content: RichtextStoryblok
}

const RichTextRenderer = ({ resolver, content }: Props) => {
  const resolvers = {
    [BlockTypes.COMPONENT]: (node: StoryblokRichTextNode<ReactElement>) => {
     if(!node || !node.attrs || node.type !== 'blok') return <></>
 
     const component = node.attrs.body[0].component as keyof typeof components
     const blok = node.attrs.body[0]
 
     const RenderElement = components[component] as React.ComponentType<{ blok: any }>
 
     return <RenderElement blok={blok} />
    },
    ...resolver
  }

  return <StoryblokRichText doc={content as any} resolvers={resolvers} />
}

export default RichTextRenderer