import { SearchResultsStoryblok } from '@/app/types/component-types-sb'
import removeMd from 'remove-markdown';
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from './SearchResults.module.css'
import React from 'react'
import { Article } from '@/app/types/lambda-types';
import { knowledgeBaseSearchFilter } from '@/utils/queries';
import Container from '@/app/components/globals/container';
import SearchFilterDisplay from '../header/SearchFilterDisplay';
import PageSection from '@/app/components/globals/page_section';
import { serverStore } from '@/utils/lib/serverstore';


const SearchResults = async ({ blok }: { blok: SearchResultsStoryblok }) => {
  const query = serverStore.get('query');
  const generateSubstringFromArticle = (article: Article, value: string) => {
    const descriptionWithoutMarkdown = removeMd(article.description);
    const indexOfSearch = descriptionWithoutMarkdown.toLowerCase().indexOf(value.toLowerCase());
    const substring = descriptionWithoutMarkdown.substring(indexOfSearch, indexOfSearch + value.length);
    const description = "..." + descriptionWithoutMarkdown.substring(indexOfSearch - 45, indexOfSearch) +
      `<span style="font-weight: bolder">${substring}</span>` +
      descriptionWithoutMarkdown.substring(indexOfSearch + value.length, indexOfSearch + 45) + "...";

    article.description = description

    return article;
  }

  const fetchData = async () => {
    const data = await knowledgeBaseSearchFilter(query as string);
    return data
  };

  const searchContent = await fetchData()

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container className={styles.container}>
          <h1 className={styles.title}>
            Results for &apos;{query}&apos; Articles
          </h1>
            {searchContent?.articles.map((article: Article, key: number) => (
              <div key={key} className={styles.searchResults}>
              <SearchFilterDisplay
                key={article.id}
                article={generateSubstringFromArticle(article, query as string)}
              />
              </div>
            ))}
        </Container>
      </PageSection>
    </div>
  )
}

export default SearchResults