import React from 'react'
import { ArticlesStoryblok, ArticleStoryblok} from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import Container from '@/app/components/globals/container';
import styles from "./Articles.module.css";
import PageSection from '@/app/components/globals/page_section';
import Article from './Article';


const Articles = ({ blok }: { blok: ArticlesStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
          <h4>{blok.title}</h4>
          <div className={styles.articles}>
            {blok.article.map((article: ArticleStoryblok) => (
              <Article blok={article} key={article._uid} />
            ))}
          </div>
    </div>
  )
}

export default Articles