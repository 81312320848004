import { Article as ArticleType } from "@/app/types/lambda-types";
import React from "react";
import styles from "./CategoryView.module.css";
import Link from "next/link";
import DynamicIcon from "@/app/components/globals/dynamic-icon";

type Props = {
  article: ArticleType;
  className?: string;
};

const Article = ({ article, className }: Props) => {
  return (
    <div className={`${styles.title} ${className}`}>
      <div style={{
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <DynamicIcon iconName={article?.icon} style="solid" />
      </div>
      <Link
        className={styles.articleTitleLink}
        href={`/knowledge-base/article/${article.uuid}`}
      >
        {article.name}
      </Link>
    </div>
  );
};

export default Article;
