import { WhatsIncludedSectionStoryblok } from '@/app/types/component-types-sb'
import React from 'react'
import { getWhatsIncluded } from '@/utils/queries';
import WhatsIncluded from './WhatsIncluded';

const WhatsIncludedData = async ({ blok }: { blok: WhatsIncludedSectionStoryblok }) => {

  const fetchWhatsIncluded = async () => {
    if (blok.enter_manually) {
      return blok.items || [];
    }

    const fetchData = async () => {
      const response = await getWhatsIncluded(
        {
          cruise_line_tt_id: parseInt(blok.cruise_lines || '1'),
          has_flights: blok.has_flights ? 1 : 0,
          has_hotels: blok.has_hotels ? 1 : 0
        }
      )
      return response
    }

    return await fetchData()
  }

  const whatsIncluded = await fetchWhatsIncluded()

  return (
    <WhatsIncluded blok={blok} whatsIncluded={whatsIncluded} />
  )
}

export default WhatsIncludedData