import React from 'react';
import { storyblokEditable } from '@storyblok/react/rsc';
import type { SignupFormStoryblok } from '@/app/types/component-types-sb';
import styles from './SignUpForm.module.css';
import TextField from '../../globals/forms/text-field';
import Button from '../../globals/button';
import RichTextRenderer from '../../globals/richtext';

const SignupForm = ({ blok }: { blok: SignupFormStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} className={styles.wrapper}>
      <div className='text-content'>
        {blok.intro_text && <RichTextRenderer content={blok.intro_text} />}

        <form action={blok.action} className={styles.form} method='post'>
          <TextField
            inputName='name'
            label='Name'
            required={true}
            value=''
            placeholder='your first name'
          />
          <TextField
            inputName='email'
            label='Email'
            required={true}
            value=''
            placeholder='example@domain.com'
          />
          <Button type='submit'>Sign Up</Button>
        </form>
      </div>
    </div>
  )
};

export default SignupForm;