import React from "react";
import type { YoutubeVideoStoryblok } from "@/app/types/component-types-sb";
import styles from './YouTubeVideo.module.css';
import { storyblokEditable } from "@storyblok/react";

/**
 * A component for rendering a URL video
 *
 * @param {YoutubeVideoStoryblok} blok - The Storyblok data for the component
 * @returns {React.ReactElement} The rendered component
 */
const VideoYouTube = ({ blok }: { blok: YoutubeVideoStoryblok }) => {
    return (
        <div {...storyblokEditable(blok)} className={styles.wrapper}>
            {blok.url && (
                <iframe width="1280" height="720"
                src={`${blok.url}?rel=0`}
                frameBorder="0"
                title={blok.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}
                referrerPolicy="strict-origin-when-cross-origin"
                className={styles.iframe}/>
            )}
        </div>
    )
}

export default VideoYouTube;