import React from "react";
import styles from "./CategoryView.module.css";
import type { Category } from "@/app/types/lambda-types";
import Link from "next/link";
import DynamicIcon from "@/app/components/globals/dynamic-icon";
type Props = {
  category: Category;
};

const Category = ({ category }: Props) => {
  return (
    <div className={styles.category}>
      <div className={styles.title}>
        <div style={{
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <DynamicIcon iconName={category.icon} style="solid" />
      </div>
        <Link className={styles.titleLink} href={`/knowledge-base/category/${category.uuid}`}>
          {category.name}
        </Link>
      </div>
      <div className={styles.articles}>
        {category.children && category.children.articles.map((child) => (
          <Link key={child.uuid} className={styles.article} href={`/knowledge-base/article/${child.uuid}`}>
            {child.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Category;
