'use client'
import styles from "./Header.module.css"
import Image from '../../globals/image';
import type { HeaderStoryblok } from '@/app/types/component-types-sb';
import Container from '../../globals/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown } from "@awesome.me/kit-d4c82d9167/icons/classic/thin";
import React, { useEffect } from 'react'
import clsx from "clsx";
import { SbBlokData, StoryblokServerComponent } from "@storyblok/react/rsc";
import { motion } from 'framer-motion';

type Props = {
  blok: HeaderStoryblok
}

const FullHeader = ({ blok }: Props) => {
  useEffect(()=>{
    localStorage.setItem('headerImage', JSON.stringify(blok.background_image));
  },[blok.background_image])
  const scrollHeader = () => {
    window.scrollTo({
      top: window.innerHeight - 310,
      behavior: 'smooth'
    });
  }

  return (
    <div className={styles.main}>
      <div className={styles.imageWrapper}>
        {blok.background_image ?
          <Image
            lazyLoad={false}
            asset={blok.background_image}
            useSource={false}
            quality={70}
            size={{
              width: 1920,
              height: 1080
            }}
            sourceSet={{
              '768': {  // Add tablet breakpoint
                size: {
                  width: 820,
                  height: 1180
                },
                crop: true
              },
              '1024': {  // Desktop size
                size: {
                  width: 1920,
                  height: 1080
                },
                crop: false
              }
            }}
          /> : null
        }

      </div>
      <motion.div
        className={styles.overlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.75,
          ease: "easeOut",
          delay: 0
        }}
      />
      {
        blok.search_bar?.map((nestedBlok: SbBlokData, index) => (
          <motion.div
            className={styles.searchContainer}
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.75,
              ease: "easeOut",
              delay: 0
            }}
          >
            <div className="container">
              <StoryblokServerComponent blok={nestedBlok} />
            </div>
          </motion.div>
        ))
      }
      <Container className={styles.container}>
        <motion.p
          className={styles.subtitle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.75,
            ease: "easeOut",
            delay: 0
          }}
        >
          {blok.section}
        </motion.p>
        <motion.h1
          className={styles.title}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.75,
            ease: "easeOut",
            delay: 0.4
          }}
        >
          {blok.title}
        </motion.h1>
        {blok.stats?.map((stat, index) => (
          <div key={index} className={styles.stats}>
            {stat.stats_item?.map((stat_item, index_) => (
              <div key={index_} className={styles.statItem}>
                <div className={styles.statImage}>
                  <Image
                    lazyLoad={false}
                    size={{
                      width: 14,
                      height: 14
                    }}
                    className={styles.statIconStyle}
                    asset={stat_item.icon || ''}
                  />
                </div>
                <p className={styles.label}>{stat_item.label}</p>
              </div>
            ))}
          </div>
        ))}

        <div className={clsx(styles.headerScrollButtonHolder, {
          [styles.statsVisible]: blok.stats && blok.stats.length > 0
        })}>
          <p className={styles.headerScrollButtonTitle}>Discover</p>
          <FontAwesomeIcon
            color='white'
            className={styles.headerScrollButton}
            size='3x'
            onClick={scrollHeader}
            icon={faArrowDown as IconProp} />
        </div>
      </Container>
    </div>
  )
}

export default FullHeader