import React from 'react'
import { storyblokEditable, StoryblokServerComponent , SbBlokData } from "@storyblok/react/rsc";
import type { PageStoryblok } from '@/app/types/component-types-sb';

const Page = ({ blok }: { blok: PageStoryblok }) => {
  return (
    <main {...storyblokEditable(blok)}>
      {
        blok.body?.map((nestedBlok: SbBlokData) => (
          <StoryblokServerComponent  blok={nestedBlok} key={nestedBlok._uid} />
        ))
      }
    </main>
  )
}

export default Page