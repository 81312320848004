'use client'
import { WeatherChartStoryblok } from '@/app/types/component-types-sb';
// import { PortGuide } from '@/app/types/lambda-types';
// import { getWeatherChartData } from '@/utils/queries';
// import { sortAndPrioritizeName } from '@/utils/actions';
import WeatherChartClient from './WeatherChartClient';


const WeatherChart = ({ blok }: { blok: WeatherChartStoryblok }) => {

  /**
 * Fetches weather data for the specified region and port.
 *
 * This function retrieves weather data based on the provided region and sub-region IDs.
 * It optionally considers the default port for prioritized sorting. The data is then
 * fetched again for the prioritized port ID if applicable and updates the state with
 * the fetched data.
 *
 * The function ensures that the data is only set if the component is still mounted.
 * It logs the initial response for debugging purposes.
 *
 * @returns {void}
 * @throws {Error} If the fetch operation fails.
 */

  // const fetchData = async () => {

  //   const region_id = blok.region_id ? blok.region_id.toString().trim().length > 0 ? blok.region_id : undefined : undefined
  //   const sub_region_id = blok.sub_region_id ? blok.sub_region_id.toString().trim().length > 0 ? blok.sub_region_id : undefined : undefined
  //   const port = blok.default_port && (blok.default_port).toString().trim().length > 0 ? blok.default_port : undefined

  //   const response = await getWeatherChartData(region_id, sub_region_id, undefined)

  //   const dataSort = port ? sortAndPrioritizeName(response.port_guide || [], sub_region_id ? "port_guide_id" : "id", port || '') : {
  //     sortedArray: [],
  //     exists: true
  //   };

  //   if (port && (port).toString().trim().length > 0) {
  //     const dataResponse = await getWeatherChartData(region_id, sub_region_id, String((dataSort.sortedArray as PortGuide[])[0][sub_region_id ? "port_guide_id" : "id"]));

  //     return {
  //       port_guide: dataSort.sortedArray as PortGuide[],
  //       weather: dataResponse.weather
  //     }
  //   } else {
  //     return response
  //   }
  // }

  // const data = await fetchData()

  return (
    <WeatherChartClient blok={blok}/>
  )
}

export default WeatherChart