'use client'

import React from 'react'
import Button from '../../globals/button'

type ScrollButtonProps = {
  label: string
  variant?: 'outline' | 'default' // add other variants as needed
}

const ScrollButton: React.FC<ScrollButtonProps> = ({ label, variant = 'default' }) => {
  const scrollToGroups = () => {
    const groups = document.getElementsByClassName('story-blokgroups')[0]
    if (!groups) return
    const offset = 100
    const elementTop = groups.getBoundingClientRect().top + window.scrollY
    const targetPosition = elementTop - offset
    window.scrollTo({ top: targetPosition, behavior: 'smooth' })
  }

  return (
    <Button onClick={scrollToGroups} variant='outline'>
      {label}
    </Button>
  )
}

export default ScrollButton