import { ShipDetailsStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React, { useEffect, useState } from 'react'
import { Barsandlounge, ShipDetail } from '@/app/types/lambda-types'
import { getShipDetailsByTTID } from '@/utils/queries';
import styles from './ShipDetails.module.css'
import Container from '../../globals/container';
import PageSection from '../../globals/page_section';
import { Overview } from '../../../types/lambda-types';
import HorizontalScrollView from '../../globals/carousels/custom_carousel';
import DestinationHightlight from '../destination_highlights/destination_highlight';
import ThingsToDoItem from '../things_to_do/things_to_do_item';
import parse from 'html-react-parser';
import Carousel from '../slider/Carousel';
import { EmblaOptionsType } from "embla-carousel";
import SliderItem from './SliderItem';

const OPTIONS: EmblaOptionsType = {
  loop: true,
  containScroll: 'trimSnaps',
}

const ShipDetails = async ({ blok }: { blok: ShipDetailsStoryblok }) => {
  let fetchingData = true;


  const fetchShipDetails = async () => {
    try {
      const response = await getShipDetailsByTTID(parseInt(blok.ship_tt_id || '0'));
      return response
    } catch (e) {
      console.error(e);
    } finally {
      fetchingData = false;
    }
  }

  const shipDetail = await fetchShipDetails();



  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          {!fetchingData && shipDetail && shipDetail?.restaurants?.length > 0 && (
            <h1 className={styles.title}>Restaurants</h1>
          )}
        </Container>
        {!fetchingData ? (
          <Carousel options={OPTIONS}>
            {shipDetail?.restaurants?.map((item, index) => (
              <SliderItem
                key={index}
                image={item.image}
                title={item.name}
                description={item.description}
              />
            ))}
          </Carousel>
        ) : (
          <Container>
            <div className={styles.sliderShimmer} />
          </Container>
        )}
      </PageSection>

      <PageSection>
        <Container>
          {!fetchingData && shipDetail && shipDetail?.barsandlounges?.length > 0 && (
            <h1 className={styles.title}>Bars and Lounges</h1>
          )}
        </Container>
        {!fetchingData ? (
          <HorizontalScrollView className={styles.horizontalScroll}>
            {shipDetail?.barsandlounges.map((overview: Barsandlounge) => (
              <ThingsToDoItem key={overview.name} items={{
                title: overview.name,
                description: overview.description,
                background_image: overview.image,
              }} />
            ))}
          </HorizontalScrollView>
        ) : (
          <HorizontalScrollView className={styles.horizontalScroll}>
            <div className={styles.tallShimmer} />
            <div className={styles.tallShimmer} />
            <div className={styles.tallShimmer} />
          </HorizontalScrollView>
        )}
      </PageSection>


      <PageSection>
        <Container>
          {!fetchingData && shipDetail && shipDetail?.activities.length > 0 && (
            <h1 className={styles.title}>Activities</h1>
          )}
        </Container>
        {!fetchingData ? (
          <HorizontalScrollView className={styles.horizontalScroll}>
            {shipDetail?.activities.map((overview: Barsandlounge) => (
              <ThingsToDoItem key={overview.name} items={{
                title: overview.name,
                description: overview.description,
                background_image: overview.image,
              }} height='short' />
            ))}
          </HorizontalScrollView>
        ) : (
          <HorizontalScrollView className={styles.horizontalScroll}>
            <div className={styles.shortShimmer} />
            <div className={styles.shortShimmer} />
            <div className={styles.shortShimmer} />
          </HorizontalScrollView>
        )}
      </PageSection>
    </div>
  )
}

export default ShipDetails