'use client'
import { StarBuyStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from './StarBuy.module.css'
import React from 'react'
import Container from '../../globals/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faStar } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { Holiday } from '@/app/types/lambda-types';
import Image from '../../globals/image';

type Props = {
  header: React.JSX.Element | null
  body: React.JSX.Element | null
  blok: StarBuyStoryblok
  holiday: Holiday | undefined
  isLoading: boolean
}

const StarBuyClient = ({ header, body, blok, isLoading, holiday }: Props) => {
  return (
    <div className="page-section" {...storyblokEditable(blok)}>
			{isLoading ? (
				<Container>
					<motion.div
						{...{
							initial: { opacity: 0, y: 25 },
							whileInView: { opacity: 1, y: 0 },
							viewport: { once: true, margin: "-100px" },
							transition: { duration: 0.6, ease: "easeInOut" }
						}}
					>
						<h2 className={clsx("section-header", styles.header)}>
							<FontAwesomeIcon icon={faStar as IconProp} />
							<span>Star Buy</span>
						</h2>
					</motion.div>
					<article className={clsx(styles.container, styles.shimmer)}>
					</article>
					<p className={styles.tsAndCs}>*Prices on display are from and per person based on 2 sharing the lowest cabin grade unless otherwise stated. Gratuities are not included in any of these offers unless otherwise stated. Booking fee & Baggage charge may apply. No Booking fee is payable when you book online. Cruise Nation and Cruise Line Terms & Conditions apply. E&OE.</p>
				</Container>
			) : (
				<Container>
					<motion.div
						{...{
							initial: { opacity: 0, y: 25 },
							whileInView: { opacity: 1, y: 0 },
							viewport: { once: true, margin: "-100px" },
							transition: { duration: 0.6, ease: "easeInOut" }
						}}
					>
						<h2 className={clsx("section-header", styles.header)}>
							<FontAwesomeIcon icon={faStar as IconProp} />
							<span>Star Buy</span>
						</h2>
					</motion.div>

					<motion.div
						{...{
							initial: { opacity: 0 },
							whileInView: { opacity: 1 },
							viewport: { once: true, margin: "-100px" },
							transition: { duration: 0.6, ease: "easeInOut" }
						}}
					>
						<article className={styles.container}>
							{holiday ? <Image asset={holiday.port_guides?.collection[0]?.image_path || ''} /> : null}
							<div className={styles.gradientOverlay}></div>
							<div className={styles.darkGradientOverlay}></div>
							{header}
							{body}
						</article>
					</motion.div>
					<p className={styles.tsAndCs}>*Prices on display are from and per person based on 2 sharing the lowest cabin grade unless otherwise stated. Gratuities are not included in any of these offers unless otherwise stated. Booking fee & Baggage charge may apply. No Booking fee is payable when you book online. Cruise Nation and Cruise Line Terms & Conditions apply. E&OE.</p>
				</Container>
			)}
		</div>
  )
}

export default StarBuyClient