'use client'

import { Group } from "@/app/types/lambda-types"
import { useEffect, useState } from "react"
import Button from "../../globals/button"
import styles from './TabberGroupCollection.module.css';
import DynamicGroup from "../../globals/dynamic_group";
import Terms from "../group/Terms";
import { getPhoneNumber } from "@/utils/queries";
import { getCookie } from "@/utils/cookies";
import GroupItem from "../../globals/dynamic_group/GroupItem";

interface TabbedGroupProps {
  groups: Group[]
}

const TabbedGroupCollectionClient = ({ groups }: TabbedGroupProps) => {
  const [selectedGroup, setSelectedGroup] = useState(groups[0]);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      const phoneNumberResponse = await getPhoneNumber(getCookie('aff'));
      setPhoneNumber(phoneNumberResponse);
    }
    fetchData()
  }, [])

  return (
    <div>
      <div className={styles.tabWrapper}>
        {
          groups.map((group: Group) => (
            <Button key={group.uuid} onClick={() => setSelectedGroup(group)} variant={group === selectedGroup ? "primary" : "outline"}>
              {group.name}
            </Button>
          ))
        }
      </div>
      <div className={styles.holidays}>
        {
          (selectedGroup.featured_deals ?? []).map((deal, index) => (
            <GroupItem key={index} holiday={deal} />
          ))
        }
        {/* <DynamicGroup
          group={selectedGroup.featured_deals}
          data_field={"2"}
          uuid={selectedGroup.uuid}
          initSort={'price_pp'}
          show_pagination={false}
          initialFilters={{
            ships: [],
            regions: [],
            dates: {
              earliest_date: '',
              latest_date: ''
            }
          }}
          onPageChange={() => { }}
          onFiltersChange={() => { }}
          setIsLoading={() => { }}
          isLoading={false}
        /> */}
      </div>
      <Terms phoneNumber={phoneNumber} />
    </div>
  )
}

export default TabbedGroupCollectionClient