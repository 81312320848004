import { Category } from "@/app/types/lambda-types";
import React from "react";
import styles from "./CategoryView.module.css";
import Link from "next/link";
import DynamicIcon from "@/app/components/globals/dynamic-icon";

type Props = {
  category: Category;
  className?: string;
};

const RelatedCategory = ({ category, className }: Props) => {
  return (
    <div className={`${styles.title} ${className}`}>
      <DynamicIcon iconName={category.icon} style="solid" />
      <Link
        className={styles.articleTitleLink}
        href={`/knowledge-base/category/${category.uuid}`}
      >
        {category.name}
      </Link>
    </div>
  );
};

export default RelatedCategory;
