import { TableStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from '@storyblok/react';
import React from 'react'
import styles from './Table.module.css'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../globals/table'

const StoryBlokTable = ({ blok }: { blok: TableStoryblok }) => {

  return (
    <div {...storyblokEditable(blok)}>
      <Table>
        <TableHeader>
          <TableRow>
            {blok.table_data?.thead?.map((head, index) => (
              <TableHead key={index}>{head.value}</TableHead>
            ))}
          </TableRow>

        </TableHeader>

        <TableBody>
          {blok.table_data?.tbody?.map((cell, index) => (
            <TableRow key={index}>
              {cell.body?.map((body, index1) => (
                <TableCell key={index1}>{body.value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default StoryBlokTable