"use client"
import { Promotion } from "@/app/types/lambda-types"
import styles from './Promotions.module.css';
import DynamicIcon from "../dynamic-icon";
import { AlertDialog, AlertDialogContent, AlertDialogTitle, AlertDialogTrigger } from "../alert-dialog/AlertDialog";
import { useState } from "react";
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from "../button";

const PromotionItem = ({ promotion }: { promotion: Promotion }) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
      <AlertDialogTrigger>
        <div className={styles.promotion}>
          {<DynamicIcon iconName={promotion.icon} style="regular" />}
          {promotion.summary}
        </div>
      </AlertDialogTrigger>
      <AlertDialogContent className={styles.alertDialogContent}>
        <AlertDialogTitle className={styles.alertDialogTitle}>{promotion.title}</AlertDialogTitle>

        <p className={styles.alertDialogSubitle}>{promotion.short_description}</p>

        <div className={styles.alertDialogDescriptionWrapper}>
          {promotion.description.map((promotionDescription, key) => (
            <p key={key} className={styles.alertDialogDescription}>{promotionDescription}</p>
          ))}
        </div>

        {(promotion.asterisk && promotion.asterisk.length > 0) && (
          <p className={styles.alertDialogAsterisk}><span>NOTE:</span> {promotion.asterisk}</p>
        )}


        <Button
          variant='transparent'
          onClick={() => setShowDialog(false)}
          className={styles.alertDialogClose}
        >
          <FontAwesomeIcon
            size='3x'
            icon={faXmark as IconProp}
            className={styles.alertDialogCloseIcon}
          />
        </Button>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default PromotionItem;