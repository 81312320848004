'use client'
import React, { useRef } from 'react';
import { storyblokEditable } from '@storyblok/react';
import Image from '../../globals/image';
import type { StackedImagesStoryblok } from '@/app/types/component-types-sb';
import styles from './StackedImages.module.css';
import { motion, MotionValue, useScroll, useSpring, useTransform } from 'framer-motion';

const StackedImages = ({ blok, side }: { blok: StackedImagesStoryblok, side: string }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['-0.5 0', '1.5 1']
  });

  const transformedPosition = useTransform(scrollYProgress, [0, 1], ['-40%', '40%'])
  const translateY = useSpring(transformedPosition, {
    stiffness: 100,
    damping: 20,
    restDelta: 0.001
  });


  return (
    <motion.div {...storyblokEditable(blok)} className={styles.wrapper} ref={ref}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.2 }}
      variants={{
        visible: { x: 0, opacity: 1 },
        hidden: { x: side === 'left' ? '-10%' : '10%', opacity: 0 },
      }}>
      <motion.div className={styles.backgroundImage + ' background-image'}>
        {blok.background_image ?
          <Image asset={blok.background_image} /> : null
        }
      </motion.div>
      <motion.div className={styles.foregroundImage + ' foreground-image'} style={{ translateY }}>
        {blok.foreground_image ?
          <Image asset={blok.foreground_image} /> : null
        }
      </motion.div>
    </motion.div>
  )
}

export default StackedImages;

function useTranform(scrollYProgress: MotionValue<number>, arg1: number[], arg2: number[]) {
  throw new Error('Function not implemented.');
}
