"use client"
import styles from './Header.module.css';
import Image from '../../globals/image';
import type { HeaderStoryblok } from '@/app/types/component-types-sb';
import React, { useEffect } from 'react';
import { SbBlokData, StoryblokServerComponent } from '@storyblok/react/rsc';
import { motion } from 'framer-motion';
import Container from '../../globals/container';

type Props = {
  blok: HeaderStoryblok
}

const MediumHeader = ({ blok }: Props) => {
  useEffect(()=>{
    localStorage.setItem('headerImage', JSON.stringify(blok.background_image));
  },[blok.background_image])
  return (
    <div className={styles.main}>
      <div className={styles.imageWrapperMedium}>
        {blok.background_image ?
          <Image
            lazyLoad={false}
            asset={blok.background_image}
            useSource={false}
            quality={70}
            size={{
              width: 1920,
              height: 400
            }}
            sourceSet={{
              '768': {  // Add tablet breakpoint
                size: {
                  width: 820,
                  height: 400
                },
                crop: true
              },
              '1024': {  // Desktop size
                size: {
                  width: 1920,
                  height: 400
                },
                crop: false
              }
            }}
          /> : null
        }

      </div>
      <motion.div
        className={styles.overlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.75,
          ease: "easeOut",
          delay: 0
        }}
      />
      {
        blok.search_bar?.map((nestedBlok: SbBlokData, index) => (
          <motion.div
            className={styles.searchContainer}
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.75,
              ease: "easeOut",
              delay: 0
            }}
          >
            <div className="container">
              <StoryblokServerComponent blok={nestedBlok} />
            </div>
          </motion.div>
        ))
      }
      <Container className={styles.container}>
        <motion.p
          className={styles.subtitle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.75,
            ease: "easeOut",
            delay: 0
          }}
        >
          {blok.section}
        </motion.p>
        <motion.h1
          className={styles.title}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.75,
            ease: "easeOut",
            delay: 0.4
          }}
        >
          {blok.title}
        </motion.h1>
      </Container>
    </div>
  )
};

export default MediumHeader;