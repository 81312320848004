'use client'
import React, { createContext, useContext, ReactNode, useRef, useEffect } from 'react';
import { forwardRef } from 'react';
import styles from './AlertDialog.module.css';
import clsx from 'clsx';

type AlertDialogContextType = {
  open: boolean;
  setOpen: (open: boolean) => void;
  dialogRef: React.RefObject<HTMLDialogElement>;
};

const AlertDialogContext = createContext<AlertDialogContextType | undefined>(undefined);

// Props types remain the same as before
interface AlertDialogProps {
  children: ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

interface AlertDialogTriggerProps {
  children: ReactNode;
  ariaLabel?: string;
  asChild?: boolean;
}

interface AlertDialogContentProps {
  children: ReactNode;
  className?: string;
  showBackdrop?: boolean;
  style?: React.CSSProperties;
}

interface AlertDialogHeaderProps {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

interface AlertDialogFooterProps {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

interface AlertDialogTitleProps {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

interface AlertDialogDescriptionProps {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

interface ButtonProps {
  className?: string;
  style?: React.CSSProperties;
  children: ReactNode;
  onClick?: () => void;
}

// Hook for context
const useAlertDialog = () => {
  const context = useContext(AlertDialogContext);
  if (!context) {
    throw new Error('useAlertDialog must be used within an AlertDialog');
  }
  return context;
};

// Components remain the same but use CSS classes instead of Tailwind
export const AlertDialog = ({ children, open: controlledOpen, onOpenChange }: AlertDialogProps) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = onOpenChange ?? setUncontrolledOpen;

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    if (open && !dialog.open) {
      dialog.showModal();
    } else if (!open && dialog.open) {
      dialog.close();
    }

    const handleClickOutside = (e: MouseEvent) => {
      const rect = dialog.getBoundingClientRect();
      const isInDialog = (rect.top <= e.clientY && e.clientY <= rect.top + rect.height
        && rect.left <= e.clientX && e.clientX <= rect.left + rect.width);
      if (!isInDialog) {
        setOpen(false);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        setOpen(false);
      }
    };
  
    if (open) {
      dialog.addEventListener('click', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    }
  
    return () => {
      dialog.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, setOpen]);

  return (
    <AlertDialogContext.Provider value={{ open, setOpen, dialogRef }}>
      {children}
    </AlertDialogContext.Provider>
  );
};

export const AlertDialogTrigger = forwardRef<HTMLButtonElement, AlertDialogTriggerProps>(
  ({ children, ariaLabel, asChild = false }, ref) => {
    const { setOpen } = useAlertDialog();

    if (asChild) {
      return React.cloneElement(children as React.ReactElement, {
        onClick: () => setOpen(true),
        ref,
      });
    }

    return (
      <button aria-label={(ariaLabel && ariaLabel !== '') ? ariaLabel : 'Open Dialog'} ref={ref} onClick={() => setOpen(true)} className={styles["alert-dialog-trigger"]}>
        {children}
      </button>
    );
  }
);

AlertDialogTrigger.displayName = 'AlertDialogTrigger';

export const AlertDialogContent = ({ children, className, showBackdrop = true, style }: AlertDialogContentProps) => {
  const { dialogRef } = useAlertDialog();

  return (
    <dialog ref={dialogRef} style={style} className={clsx({
      [styles["alert-dialog-content"]]: showBackdrop,
      [styles["alert-dialog-content-without-backdrop"]]: !showBackdrop,
    }, className)}>
      {children}
    </dialog>
  );
};

export const AlertDialogHeader = ({ children, className, style }: AlertDialogHeaderProps) => {
  return <div className={clsx(styles["alert-dialog-header"], className)} style={style}>{children}</div>;
};

export const AlertDialogFooter = ({ children, className, style }: AlertDialogFooterProps) => {
  return <div className={clsx(styles["alert-dialog-footer"], className)} style={style}>{children}</div>;
};

export const AlertDialogTitle = ({ children, className, style }: AlertDialogTitleProps) => {
  return <h2 className={clsx(styles["alert-dialog-title"], className)} style={style}>{children}</h2>;
};

export const AlertDialogDescription = ({ children, className, style }: AlertDialogDescriptionProps) => {
  return <p className={clsx(styles["alert-dialog-description"], className)} style={style}>{children}</p>;
};

export const AlertDialogAction = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, onClick, style, className }, ref) => {
    const { setOpen } = useAlertDialog();

    const handleClick = () => {
      onClick?.();
      setOpen(false);
    };

    return (
      <button ref={ref} onClick={handleClick} className={clsx(styles["alert-dialog-action"], className)} style={style}>
        {children}
      </button>
    );
  }
);

AlertDialogAction.displayName = 'AlertDialogAction';

export const AlertDialogCancel = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, onClick, style, className }, ref) => {
    const { setOpen } = useAlertDialog();

    const handleClick = () => {
      onClick?.();
      setOpen(false);
    };

    return (
      <button ref={ref} onClick={handleClick} className={clsx(styles["alert-dialog-cancel"], className)} style={style}>
        {children}
      </button>
    );
  }
);

AlertDialogCancel.displayName = 'AlertDialogCancel';