
import CustomLink from '../../globals/link'
import styles from './Group.module.css'

interface TermsProps {
  phoneNumber?: string
}

const Terms = ({ phoneNumber }: TermsProps) => {
  return (
    <div className={styles.terms}>
      <h4>* Price information </h4>
      <p>Prices on display are from and per person based on 2 sharing the lowest cabin grade unless otherwise stated.
        Gratuities are not included in any of these offers unless otherwise stated. Booking fee & Baggage charge may apply.
        No Booking fee is payable when you book online.
        Cruise Nation and Cruise Line Terms & Conditions apply. E&OE.</p>
      <p>
        For the latest and most up to date availability & pricing, please click on a deal or call our team on {' '}
        {phoneNumber && (
          <CustomLink href={`tel:${phoneNumber.replaceAll(/\s/g, '')}`} variant='link'>
            {phoneNumber}
          </CustomLink>
        )}
      </p>
    </div>
  )
}

export default Terms;